import React from 'react'; // eslint-disable-line no-unused-vars
import { Box, Stack } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import { Title, List, Paragraph } from '../components/Texts';
import Layout from '../components/Layout';

const Terraobs = () => (
  <Layout title="TerraObs">
    <Box>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', width: 400, display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/2021-11/logo%20terraobs04032021.png"
          alt="Logo_TerraObs"
          loading="lazy"
        />
      </Box>
      <Title level={3} sx={{ mt: 6 }}>
        Développer l'attractivité des centres villes,<br />
        pilier du plan Action Cœur de Ville
      </Title>
      <Paragraph>
        Le commerce de proximité connaît des mutations liées, entre autres, à la
        croissance de l'e-commerce. Ainsi en 2018, selon Médiamétrie, 37,5
        millions de Français ont acheté sur Internet. D'après une étude réalisée
        par{' '}
        <Link href="https://www.procos.org/">
          PROCOS
        </Link>{' '}
        cette même année, soit avant la crise sanitaire, le taux de vacance
        commercial moyen s'élevait à 13%.
      </Paragraph>
      <Paragraph>
        Le plan gouvernemental "
        <Link href="https://www.cohesion-territoires.gouv.fr/programme-action-coeur-de-ville">
          Action Cœur de Ville
        </Link>
        ", qui comprend plusieurs volets, compte parmi ses objectifs la lutte
        contre la vacance commerciale. La revitalisation des centres-villes est
        donc au cœur des préoccupations des acteurs du développement territorial.
        Ainsi, pour piloter la stratégie de dynamisation du territoire, ils
        doivent disposer d' une connaissance fine de l'activité commerciale.
        L'identification et la visualisation des locaux commerciaux occupés et
        vacants sont donc un enjeu de taille.
      </Paragraph>
      <Paragraph>
        C'est pour répondre à ce besoin que Makina Corpus et{' '}
        <Link href="https://www.solutions-territoriales.fr/apropos">
          Autonomens
        </Link>{' '}
        ont créé{' '}
        <Link href="https://www.solutions-territoriales.fr/terraobs">
          TerraObs
        </Link>
        , un Observatoire de l'immobilier commercial.
      </Paragraph>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/2021-11/image.png"
          alt="TerraObs_demo_3D"
          loading="lazy"
        />
      </Box>
      <Title level={2} sx={{ mt: 8 }}>Analysez les mutations commerciales avec TerraObs</Title>
      <Paragraph>
        TerraObs est une application cartographique qui affiche l'état de
        l'immobilier commercial des centres-villes en temps réel. Elle s'adresse
        principalement aux managers de commerce, aux élus ou encore aux porteurs
        de projets.
      </Paragraph>
      <Paragraph>
        L’application est une carte interactive sur laquelle sont indiquées les
        données foncières et commerciales propres au territoire affiché, qui
        s’inscrivent dans son écosystème socio-économique.
      </Paragraph>
      <Paragraph>
        Ces données, issues de l'open data, sont riches d’informations (services
        publics, réseaux de transports, revenus, habitat, populations, etc.) et
        permettent de contextualiser et analyser les mutations du territoire.
      </Paragraph>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/2021-11/TeraObs_demo.png"
          alt="Démonstration TerraObs"
          loading="lazy"
        />
      </Box>
      <Title level={3} sx={{ mt: 6 }}>Cas d'usage</Title>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/styles/150/public/2021-08/Agence%20d%27Urbanisme%20d%27Agglom%C3%A9rations%20de%20Moselle%20%E2%80%93%20AGURAM%20copie.png?itok=Q9bySwOl"
          alt="Agence d'Urbanisme d'Agglomérations de Moselle – AGURAM"
          loading="lazy"
        />
      </Box>
      <Paragraph>
        L'AGURAM (Agence d'Urbanisme d'Agglomérations de Moselle) a confié à
        Makina Corpus le développement d'un TerraObs nommé ImObserver pour la
        communauté d'agglomérations de Thionville. Cet outil est utilisé
        quotidiennnement par le manager de commerce pour porter ses projets et
        développer l'attractivité des centres-villes.
      </Paragraph>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/2021-07/aguram4.png"
          alt="Aguram IM'OBSERVER"
          loading="lazy"
        />
      </Box>
      <Title level={3} sx={{ mt: 6 }}>Im'Observer : label Argent "Territoire Innovant 2021"</Title>
      <Paragraph>
        Les{' '}
        <Link
          href="https://www.interconnectes.com/label-territoire-innovant/"
        >
          Interconnectes
        </Link>{' '}
        ont décerné le{' '}
        <strong>
          label Argent "Territoire Innovant 2021" pour l'application{' '}
          <Link
            href="https://makina-corpus.com/sig-webmapping/observatoire-immobilier-commercial#corps"
          >
            Im'Observer
          </Link>
          ,{' '}
        </strong>
        l'Observatoire de l'immobilier commercial, à la{' '}
        <Link href="https://www.agglo-thionville.fr/">
          Communauté d'Agglomération Portes de France-Thionville
        </Link>{' '}
        et la{' '}
        <Link href="https://www.agglo-saint-avold.fr/">
          Communauté d'Agglomération Saint-Avold Synergie
        </Link>{' '}
        (CASAS).
      </Paragraph>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/2021-11/TerraObs_Label-Territoire-Innovant_0.jpg"
          alt="TerraObs : Label territoire innovant ImObserver"
          loading="lazy"
        />
      </Box>
      <Paragraph>
        "Bravo aux deux collectivités pour votre engagement au service de la
        redynamisation de nos centres-villes, c'est un super projet collectif :
      </Paragraph>
      <List>
        <List item>
          Prôné et accompagné par l'
          <Link href="https://www.aguram.org/aguram/">
            Agence AGURAM
          </Link>{' '}
          dans le cadre d'
          <Link
            href="https://agence-cohesion-territoires.gouv.fr/action-coeur-de-ville-42"
          >
            Action Cœur de Ville
          </Link>
        </List>
        <List item>Soutenu par la Banque Des Territoires Groupe Caisse des Dépôts</List>
        <List item>
          Développé techniquement par Makina Corpus dans un partenariat tout à
          fait original
        </List>
        <List item>
          Valorisé lors d'un{' '}
          <Link href="https://www.linkedin.com/feed/hashtag/?keywords=ami&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6869955768804892673">
            AMI
          </Link>
          <Link
            href="https://www.banquedesterritoires.fr/un-ami-sur-la-data-city-lechelle-des-villes-moyennes"
          >
            {' '}
            (Appel à Manifestation d'Intérêt) "Data City"
          </Link>{' '}
          de la Banque des Territoires et d'
          <Link href="https://www.opendatafrance.net/">
            OpenDataFrance
          </Link>
        </List>
      </List>
      <Paragraph>
        Cette application s'inscrit pleinement dans l'ADN d'une agence d'urbanisme
        : innover, mutualiser, rendre interopérable, donner à voir et être utile
        concrètement aux territoires et à ceux qui y vivent. Dans les prochains
        mois, les nouveaux étages de cette fusée seront visibles avec l'essaimage
        d'Im'Observer sur l'
        <Link href="https://www.eurometropolemetz.eu/">
          Eurométropole de Metz
        </Link>{' '}
        ou le nouveau module{' '}
        <Link
          href="https://www.linkedin.com/feed/hashtag/?keywords=placeaulocal&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6869955768804892673"
        >
          PlaceAuLocal
        </Link>
        , véritable répertoire des produits et producteurs locaux sur le
        territoire de la CASAS. Longue vie à Im'Observer ! Un grand bravo à tous
        les territoires récompensés aujourd'hui par ce label : l'innovation est
        présente partout et de nombreux cas d'usages sont à faire connaître et
        reproduire."
      </Paragraph>
      <Box>
        <Paragraph>
          Pascal CHEVALLOT - Chef de projet Système
          d’Information, Pôle Ressources et Communication - AGURAM
        </Paragraph>
      </Box>
      <Title level={3} sx={{ mt: 6 }}>Démonstration</Title>
      <Paragraph>
        Si vous voulez en découvrir davantage,{' '}
        <Link
          href="https://demo-terraobs.autonomens.com/visualiser/explorer#layers=ef3eb1a4caf9c85e953e6fb94c6ce623,05f1094a1b7e36fe0a517ea05125fcf5&map=16.33/49.358126/6.165571"
        >
          consultez notre démonstration
        </Link>{' '}
        !
      </Paragraph>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/2021-11/TerraObs_demo.png"
          alt="TerraObs_demo"
          loading="lazy"
        />
      </Box>
      <Title level={3} sx={{ mt: 6 }}>Expertises et technologies</Title>
      <List>
        <List item>
          <Link href="https://makina-corpus.com/sig-webmapping">
            Cartographie
          </Link>
        </List>
        <List item>
          <Link
            href="https://makina-corpus.com/formations/sigwebmapping/formation-postgis"
          >
            PostGIS
          </Link>
        </List>
        <List item>
          <Link
            href="https://makina-corpus.com/formations/python/formation-python-avance"
          >
            Python
          </Link>
          ,{' '}
          <Link
            href="https://makina-corpus.com/formations/django/formation-django-rest-framework"
          >
            framewok Django
          </Link>
        </List>
        <List item>Elastic Search</List>
        <List item>
          <Link
            href="https://makina-corpus.com/formations/mobile/formation-react-native"
          >
            React
          </Link>
          , React-Admin
        </List>
        <List item>Mapbox</List>
      </List>
      <Title level={3} sx={{ mt: 6 }}>La presse en parle</Title>
      <List>
        <List item>
          <em>Décryptagéo</em>, le 23 novembre 2021, "
          <Link
            href="https://decryptageo.fr/makina-corpus-autonomens-innovent-3-nouvelles-applications-service-de-lamenagement-territoires-de-leurs-usagers/"
          >
            Trois nouvelles applications au service de l'aménagement des
            territoires et de leurs usagers
          </Link>
          "
        </List>
        <List item>
          <em>GeoRezo</em>, le 24 novembre 2021, "
          <Link
            href="https://georezo.net/forum/viewtopic.php?pid=348066"
          >
            Innovation : nouvelles solutions au service de l'aménagement des
            territoires et de leurs usagers
          </Link>
          "
        </List>
        <List item>
          <em>TVDiCi</em>, le 25 novembre 2021, "
          <Link
            href="https://tvdici.fr/post/makina-corpus-et-autonomens-innovent-avec-3-nouvelles-applications-au-service-de-lamenagement-des-territoires-et-de-leurs-usagers"
          >
            Makina Corpus et Autonomens innovent avec 3 nouvelles applications au
            service de l'aménagement des territoires et de leurs usagers
          </Link>
          "
        </List>
        <List item>
          <em>Veille Carto 2.0</em>, le 27 novembre 2021, "
          <Link
            href="https://veillecarto2-0.fr/2021/11/27/de-nouveaux-outils-au-service-de-lamenagement-du-territoire/"
          >
            De nouveaux outils au service de l'aménagement du territoire
          </Link>
          "
        </List>
        <List item>
          <em>Afigéo, </em>le 30 novembre 2021, "
          <Link
            href="https://www.afigeo.asso.fr/focus-les-dernieres-actualites-de-nos-adherents-sogefi-geofoncier-geofit-group-geoconcept-makina-corpus/"
          >
            FOCUS - Les dernières actualités de nos adhérents : Makina Corpus,
            Sogefi, Geofoncier, Geofit Group, Geoconcept
          </Link>
          "
        </List>
        <List item>
          <em>La Gazette du Midi</em>, le 29 novembre 2021, "Makina Corpus et
          Autonomens innovent avec trois nouvelles applications"
        </List>
      </List>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/styles/600x500/public/2021-11/Visuel%20La%20Gazette%20Du%20Midi_0.png?itok=2z1zSZJY"
          alt="Article La Gazette du Midi"
          loading="lazy"
        />
      </Box>
      <List>
        <List item>
          Supplément <em>Le guide de la donnée des territoires</em>{' '}
          de la <em>Gazette des Communes</em>, semaine du 24 au 30 janvier, Chef
          de projet Système d’Information AGURAM :{' '}
          <Link href="https://makina-corpus.com/sites/default/files/2022-01/Gazette_Communes_Guide_Donnee_Territoire.pdf">
            Gazette_Communes_Guide_Donnee_Territoire.pdf
          </Link>
        </List>
      </List>
      <Box>
        <Box>
          <Box>
            <Box
              component="img"
              sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
              src="https://makina-corpus.com/sites/default/files/2022-01/Gazette_Communes_AGURAM_1.png"
              alt="Gazette des Commune AGURAM Im'Observer"
              loading="lazy"
            />
            <Box
              component="img"
              sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
              src="https://makina-corpus.com/sites/default/files/2022-01/Gazette_Communes_AGURAM_2.png"
              alt="Gazette des Commune AGURAM Im'Observer - 2"
              loading="lazy"
            />
          </Box>
          <List>
            <List item>
              AGURAM, 5 avril 2022,{' '}
              <Link
                href="https://www.aguram.org/aguram/redynamisation-commerce-circuits-court-mobilite-quand-le-numerique-booste-vos-politiques-publiques/"
              >
                <em>
                  Redynamisation, commerce, circuits-courts, mobilité : quand le
                  numérique booste vos politiques publiques
                </em>
              </Link>
            </List>
          </List>
          <Box>
            <Box
              component="img"
              sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
              src="https://makina-corpus.com/sites/default/files/2022-04/Capture%20d%E2%80%99e%CC%81cran%202022-04-26%20a%CC%80%2014.38.58.png"
              alt="Presse AGURAM : TerraObs"
              loading="lazy"
            />
          </Box>
        </Box>
        <Paragraph>&nbsp;</Paragraph>
      </Box>
      <Title level={3} sx={{ mt: 6 }}>
        Autonomens et Makina Corpus, concepteurs de TerraObs
      </Title>
      <Paragraph>
        <Link href="https://www.solutions-territoriales.fr/apropos">
          Autonomens
        </Link>{' '}
        est une société qui développe des solutions d'aménagement des territoires
        à destination des collectivités et des organismes privés. Partenaire de
        Makina Corpus, elle s'appuie sur les savoir-faire en logiciels libres,
        analyse de données et visualisation cartographique.
      </Paragraph>
      <Box>
        <Box
          component="img"
          sx={{ maxWidth: '100%', display: 'block', mx: 'auto' }}
          src="https://makina-corpus.com/sites/default/files/2021-11/Logos_Autonomens_Makina%282%29.png"
          alt="Logos_Autonomens_Makina"
          loading="lazy"
        />
      </Box>
      <Title level={3} sx={{ mt: 6 }}>Plus d'infos</Title>
      <Paragraph>
        Pour plus d'informations, rendez-vous sur notre site
        <Link href="https://www.solutions-territoriales.fr/">
          {' '}
          Solutions Territoriales
        </Link>{' '}
        !
      </Paragraph>
      <Title level={2} sx={{ mt: 8 }}>Vous avez un projet ?</Title>
      <Paragraph>
        Vous souhaitez valoriser l'immobilier commercial de votre territoire?
        N'hésitez pas à nous contacter pour échanger avec nous sur votre projet
        :{' '}
        <Link to="/contact">
          contactez-nous{' '}
        </Link>
        !
      </Paragraph>
    </Box>

    <Stack direction="row" alignItems="center">
      <Box
        component="img"
        sx={{ maxWidth: '100%', width: 150, display: 'block', mr: 2 }}
        src="https://makina-corpus.com/sites/default/files/2022-03/IMG_20220307_101342.jpg"
        alt="Mathilde_Lestoquoy"
        loading="lazy"
      />
      <Box>
        <Link
          title="Voir les autres articles de Mathilde Lestoquoy"
          href="/societe/mathilde-lestoquoy"
        >
          Mathilde Lestoquoy
        </Link>
        <Paragraph sx={{ fontWeight: 'bold' }}>
          Assistante en Communication - Marketing
        </Paragraph>
      </Box>
    </Stack>
  </Layout>
);

export default Terraobs;
